import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { TrailguidePlugin, Gallery, Thumb } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The next `}<a parentName="p" {...{
        "href": "http://www.trailtrophy.eu"
      }}>{`Trail Trophy Enduro Series`}</a>{` takes place
in Latsch in Südtirol, Italy on the 25th-27th of May. The venue offers Italian
culture, high mountains and great trails.`}</p>
    <br />
    <p>{`During the three days of racing you
will get to see a lot of the valley around the MTB hot spot of Latsch.
A good mix of self propelled pedaling and lift support lets you enjoy many
vertical meters of descent.`}</p>
    <Gallery mdxType="Gallery">
  <Thumb src="news/ttlatsch18_4.jpg" mdxType="Thumb">
    Awesome view over the valley and on the Ortler
  </Thumb>
  <Thumb src="news/ttlatsch18_3.jpg" mdxType="Thumb">Propain Trail</Thumb>
  <Thumb src="news/ttlatsch18_1.jpg" mdxType="Thumb">Panorama Trail</Thumb>
  <Thumb src="news/ttlatsch18_7.jpg" mdxType="Thumb">Can you please bring us up?</Thumb>
  <Thumb src="news/ttlatsch18_8.jpg" mdxType="Thumb">Switchbacks.</Thumb>
  <Thumb src="news/ttlatsch18_6.jpg" mdxType="Thumb">
    You will also find nice steep and technical trails.
  </Thumb>
  <Thumb src="news/ttlatsch18_2.jpg" mdxType="Thumb">
    Built and natural singletrails make a great mix.
  </Thumb>
    </Gallery>
    <br />
    <p><a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` is an official partner of the
Trail Trophy Enduro Series and provides the stage maps and event information
for all competitors and spectators. You can win 3 tickets to this race.
How to win? Share our Facebook post that links to this blog post and
comment the post on our Facebook page. The winners are drawn on Sunday
evening (13.05.) `}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook page`}</a></p>
    <br />
    <p>{`See the Vinschgau and the area around Latsch on Trailguide. Click on the
trails to see more information or open the trail list (little arrow top left):`}</p>
    <TrailguidePlugin content="lat=46.6284&lng=10.8939&coverage=7" mdxType="TrailguidePlugin" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      